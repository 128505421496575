import { Promise } from 'rsvp';
import { later } from '@ember/runloop';

import Service, { inject as service } from '@ember/service';

export default Service.extend({
  router: service(),
  requestService: service(),
  configService: service(),
  headlessService: service(),
  settingsService: service(),
  queryCache: service(),
  notificationService: service(),
  standardReports: null,
  sharedReports: null,
  myReports: null,
  folders: [],
  expandedFolders: [],
  reportFavorites: [],
  tablevalueCache: {},
  isLoading: false,

  executeVisual(visualDefinition) {
    return this.requestService.postResource('/visual/execute', {
      datasourceid: this.configService.get('currentdatasourceId'),
      visualconfig: visualDefinition,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    });
  },

  executeVisualById(visualId, timeRangeOverride) {
    return this.requestService.postWithoutLoginHandler('/visual/execute', {
      datasourceid: this.configService.get('currentdatasourceId'),
      visualid: visualId,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
      timeRangeOverride: timeRangeOverride,
    });
  },
  validateCalculatedField(field, datasource) {
    let request = {
      field,
      datasource,
    };

    return this.requestService.postResource(
      '/datasource/validatecalculatedfield',
      request
    );
  },

  getCTETableFieldValues(dataSource, field) {
    let data = {
      dataSource,
      field,
      timezone: this.settingsService.timezone.trim(),
    };

    return new Promise((resolve, reject) => {
      this.executeAsyncQuery(
        '/describedatasource/ctetable/values/async',
        data,
        {
          onSuccess: function (result) {
            var list = [];

            result.results.forEach((r) => {
              list.pushObject(r[0]);
            });

            resolve(list.sort());
          },
          onFailure: reject,
        }
      );
    });
  },

  getWithClauseData(database, withClause, otherCTEs) {
    withClause.timezone = this.settingsService.timezone;
    withClause.database = database;

    if (otherCTEs) {
      withClause.otherCTEs = [];

      otherCTEs.forEach((cte) => {
        if (cte.name != withClause.name) {
          cte.otherCTEs = [];
          withClause.otherCTEs.pushObject(cte);
        }
      });
    }

    return new Promise((resolve, reject) => {
      this.executeAsyncQuery('/datasource/previewcte/async', withClause, {
        onSuccess: resolve,
        onFailure: reject,
      });
    });
  },

  executeAsyncQuery(url, body, handlers) {
    let pageDownloading = 1;
    //handlers: onSuccess, onFailure, onQuerySubmitted, onPageDownload
    let self = this;

    if (!handlers) {
      handlers = {};
    }

    let resolve = handlers.onSuccess || function () {};
    let reject = handlers.onFailure || function () {};

    function getAsyncQueryResults(asyncDetails) {
      self.queryCache
        .postResource('/report/execute/async/results', asyncDetails)
        .then((asyncResponse) => {
          if (!asyncResponse) {
            later(() => {
              getAsyncQueryResults(asyncDetails);
            }, 1000);
            return;
          }
          let data = asyncResponse;
          if (data.nextPageToken && data.nextPageToken !== '') {
            function getPage(nextToken) {
              pageDownloading++;
              if (handlers.onPageDownload) {
                handlers.onPageDownload(pageDownloading);
              }

              self.requestService
                .getResource(
                  `/report/execute/page/${
                    data.queryId
                  }?nextPage=${encodeURIComponent(nextToken)}`
                )
                .then((page) => {
                  if (page.results.length > 0) {
                    data.results.pushObjects(page.results);
                  }

                  if (page.nextPageToken && page.nextPageToken !== '') {
                    getPage(page.nextPageToken);
                  } else {
                    self.queryCache.cacheResults(
                      '/report/execute/async/results',
                      asyncDetails,
                      data
                    );
                    resolve(data);
                  }
                })
                .catch((e) => {
                  self.set('pageDownloading', null);
                  reject(e);
                });
            }

            getPage(data.nextPageToken);
          } else {
            self.queryCache.cacheResults(
              '/report/execute/async/results',
              asyncDetails,
              data
            );
            resolve(data);
          }
        })
        .catch((ex) => {
          if (handlers.onFailure) {
            handlers.onFailure(ex);
          } else {
            console.warn(
              `No on failure handler for ${url}, ${JSON.stringify(body)}`
            );
          }
        });
    }

    let queryRequest = null;

    if (body == null) {
      queryRequest = this.queryCache.getResource(url);
    } else {
      queryRequest = this.queryCache.postResource(url, body);
    }

    queryRequest
      .then((asyncResponse) => {
        this.queryCache.cacheResults(url, body, asyncResponse);

        if (handlers.onQuerySubmitted) {
          handlers.onQuerySubmitted(asyncResponse);
        }

        let delay = 1000;

        if (asyncResponse.resultsDelay && asyncResponse.resultsDelay >= 100) {
          delay = asyncResponse.resultsDelay;
        }

        later(() => {
          getAsyncQueryResults(asyncResponse);
        }, delay);
      })
      .catch((e) => {
        if (handlers.onFailure) {
          handlers.onFailure(e);
        } else {
          console.warn(
            `No on failure handler for ${url}, ${JSON.stringify(body)}`
          );
        }
      });
  },

  runQueryExportToXlsx(name, queryId) {
    return new Promise((resolve, reject) => {
      let dataSource = this.configService.get('currentdatasourceId');

      let url = `/xslx/execute/${dataSource}/${queryId}/${name}.xlsx`;
      this.requestService.getResource(url).finally(() => {
        let retryCount = 0;

        let interval = setInterval(() => {
          let url = `/xslx/retrieve/${dataSource}/${queryId}/${name}.xlsx`;
          this.requestService.getResource(url).then((result) => {
            if (result && typeof result.url != 'undefined' && result.url) {
              window.open(result.url);
              clearInterval(interval);
              resolve();
            } else {
              retryCount = retryCount + 1;

              if (retryCount == 100) {
                clearInterval(interval);
                reject();
              }
            }
          });
        }, 3000);
      });
    });
  },
  executeReportById(visualId, timeRangeOverride) {
    return this.requestService.postWithoutLoginHandler('/visual/execute', {
      datasourceid: this.configService.get('currentdatasourceId'),
      visualid: visualId,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
      timeRangeOverride: timeRangeOverride,
    });
  },

  updateReport(report, newVersion = true) {
    var isMyReport = false;
    var isSharedReport = false;
    for (let x = 0; x < this.myReports.length; x++) {
      if (this.myReports[x].id === report.id) {
        this.myReports[x] = report;
        isMyReport = true;
        break;
      }
    }
    if (!isMyReport) {
      for (let x = 0; x < this.sharedReports.length; x++) {
        if (this.sharedReports[x].id === report.id) {
          this.sharedReports[x] = report;
          isSharedReport = true;
          break;
        }
      }
    }

    this.set('reportLastUpdate', new Date().getTime());

    this.queryCache.clearItem('/report', null);
    let url = `/report/${report.id}`;
    if (newVersion == false) {
      url = `${url}?newVersion=false`;
    }
    return this.requestService
      .postResource(url, report)
      .then((response) => {
        if (isMyReport) {
          this.getMyReports(true);
        } else if (isSharedReport) {
          this.getSharedReports(true);
        }
        return response;
      })
      .catch((ex) => {
        if (ex.responseJSON && ex.responseJSON.message) {
          this.notificationService.error(`${ex.responseJSON.message}`, false);
        } else {
          this.notificationService.error(
            'An unknown error occured while saving your report.'
          );
        }
      });
  },
  getVisualById(visualId) {
    return this.requestService.getResource('/visual/' + visualId);
  },
  getVisualResult(queryId, nextPageToken) {
    let dataSourceId = this.configService.get('currentdatasourceId');
    let uri = `/visual/result/${dataSourceId}/${queryId}`;

    if (nextPageToken) {
      uri = `${uri}?nexttoken=${nextPageToken}`;
    }

    return this.requestService.getResource(uri);
  },
  saveVisual(visual, reportId) {
    return this.requestService
      .postResource(`/report/${reportId}/visual`, visual)
      .then((report) => {
        this.updateReport(report);
      });
  },
  logReportView(reportId) {
    return this.requestService.postResource(`/report/${reportId}/view`, {});
  },
  deleteVisual(reportId, visualId) {
    return this.requestService
      .deleteResource(`/report/${reportId}/visual/${visualId}`)
      .then((report) => {
        this.updateReport(report);
      });
  },
  copyToMe(reportId) {
    this.queryCache.clearItem('/report', null);
    return this.requestService
      .postResource(`/report/${reportId}/copy`, {})
      .then((report) => {
        this.myReports.pushObject(report);

        this.set('reportLastUpdate', new Date().getTime());

        this.getMyReports(true);

        return report;
      });
  },
  moveReportToFolder(id, folder) {
    if (this.myReports) {
      for (let x = 0; x < this.myReports.length; x++) {
        let report = this.myReports[x];
        if (report.id === id) {
          report.folder = folder;
          this.updateReport(report);
        }
      }
    }
  },
  getReport(id) {
    if (this.myReports) {
      for (let x = 0; x < this.myReports.length; x++) {
        let report = this.myReports[x];
        if (report.id === id) {
          return Promise.resolve(report);
        }
      }
    }

    if (this.sharedReports) {
      for (let x = 0; x < this.sharedReports.length; x++) {
        let report = this.sharedReports[x];
        if (report.id === id) {
          return Promise.resolve(report);
        }
      }
    }

    if (this.standardReports) {
      for (let x = 0; x < this.standardReports.length; x++) {
        let report = this.standardReports[x];
        if (report.id === id) {
          return Promise.resolve(report);
        }
      }
    }

    return this.requestService.getResource(`/report/${id}`);
  },

  getReportSchedule(reportId) {
    return this.requestService.getResource(`/report/${reportId}/schedule`);
  },
  saveReportSchedule(reportId, schedule) {
    return this.requestService.postResource(
      `/report/${reportId}/schedule`,
      schedule
    );
  },
  deleteReport(reportId) {
    let report = this.myReports.findBy('id', reportId);
    this.myReports.removeObject(report);
    this.set('reportLastUpdate', new Date().getTime());

    return this.requestService
      .deleteResource(`/report/${reportId}`)
      .then(() => {
        this.getMyReports(true);
      });
  },
  new(reportName, folder) {
    let self = this;
    return this.requestService
      .postResource('/report', {
        name: reportName,
        folder: folder.replace(' / ', '|'),
      })
      .then((report) => {
        self.myReports.pushObject(report);

        self.set('reportLastUpdate', new Date().getTime());

        self.get('router').transitionTo('report', report.id);
        self.getMyReports(true);
      })
      .catch((err) => {
        if (err.responseJSON && err.responseJSON.message) {
          this.notificationService.error(err.responseJSON.message);
        } else {
          this.notificationService.error(JSON.stringify(err));
        }
      });
  },
  getStandardReports() {
    if (this.headlessService.isHeadless()) {
      return;
    }

    let self = this;

    return this.queryCache
      .getResourceWithExpiration(
        '/report/standard',
        new moment().add(5, 'm').unix(),
        false
      )
      .then((response) => {
        self.set('standardReports', response.reports);
      });
  },
  getSharedReports() {
    if (this.headlessService.isHeadless()) {
      return;
    }

    let self = this;

    return this.queryCache
      .getResourceWithExpiration(
        '/report/shared',
        new moment().add(5, 'm').unix(),
        false
      )
      .then((response) => {
        self.set('sharedReports', response.reports);
      });
  },
  getMyReports(forceRefresh) {
    if (this.headlessService.isHeadless()) {
      return;
    }

    let self = this;

    return this.queryCache
      .getResourceWithExpiration(
        '/report/me',
        new moment().add(5, 'm').unix(),
        forceRefresh
      )
      .then((response) => {
        self.set('myReports', response.reports);
      });
  },
  getReportFavorites() {
    if (this.headlessService.isHeadless()) {
      return;
    }

    let self = this;

    return this.requestService
      .getResource('/report/favorites')
      .then((response) => {
        self.set('reportFavorites', response.reports);
      });
  },
  addReportFavorite(reportId) {
    this.reportFavorites.pushObject(reportId);
    this.requestService.putResource(`/report/${reportId}/favorite`, {});
  },
  deleteReportFavorite(reportId) {
    this.reportFavorites.removeObject(reportId);
    this.requestService.deleteResource(`/report/${reportId}/favorite`);
  },

  getReportFolders() {
    let folders = [];

    this.myReports.forEach((report) => {
      let folder = report.folder.replace(/\/\//g, '/');

      if (folders.indexOf(folder) === -1) {
        folders.pushObject(folder);
      }

      //make sure sub folders are in list
      let pathParts = folder.split('/');
      while (pathParts.length > 0) {
        pathParts = pathParts.slice(0, pathParts.length - 1);
        let subfolder = pathParts.join('/');

        if (folders.indexOf(subfolder) === -1) {
          folders.pushObject(subfolder);
        }
      }
    });

    return folders.sort();
  },

  init() {
    this._super(...arguments);
    this.getStandardReports();
    this.getSharedReports();
    this.getMyReports(false);

    this.getReportFavorites();
  },

  sortIntoFolders(reports, isSharedTree, filter, replaceCharacters) {
    if (!reports || reports.length === 0) {
      return;
    }

    filter = filter.toLocaleLowerCase();

    let tree = {
      reports: [],
      folders: [],
    };

    reports = reports.sortBy('folder');

    reports.forEach((report) => {
      let folder = report.folder.replace(/\/\//g, '/');

      if (replaceCharacters) {
        replaceCharacters.forEach((c) => {
          folder = folder.replace(c, '');
        });
      }

      if (folder && folder !== '') {
        if (isSharedTree === true) {
          folder = folder.replace('Shared/', '');
          folder = folder.replace('Shared', '');
        }
      }
      if (folder && folder !== '') {
        //ensure we don't have leading or trailing whitespace or slashes
        folder = folder.trim().replace(/^\/+/, '').replace(/\/+$/, '');
      }

      if (folder && folder !== '') {
        if (
          filter != '' &&
          report.name.toLocaleLowerCase().indexOf(filter) == -1 &&
          folder.toLocaleLowerCase().indexOf(filter) == -1
        ) {
          return;
        }

        let folderChildren = folder.split('/');

        //Make sure folder path exists
        let evalFolder = tree;
        let fullPathNodes = [];
        folderChildren.forEach((c) => {
          fullPathNodes.pushObject(c);

          if (!evalFolder.folders.findBy('name', c)) {
            evalFolder.folders.pushObject({
              name: c,
              fullPath: fullPathNodes.join('/'),
              folders: [],
              reports: [],
            });

            evalFolder.folders = evalFolder.folders.sortBy('name');
          }

          evalFolder = evalFolder.folders.findBy('name', c);
        });

        evalFolder.reports.pushObject(report);
        evalFolder.reports = evalFolder.reports.sortBy('name');

        if (evalFolder.folders && evalFolder.folders.length > 0) {
          evalFolder.folders = evalFolder.folders.sortBy('name');
        }
      } else {
        if (
          filter == '' ||
          report.name.toLocaleLowerCase().indexOf(filter) > -1
        ) {
          tree.reports.push(report);
          tree.reports = tree.reports.sortBy('name');
        }
      }
    });

    if (tree.folders && tree.folders.length > 0) {
      tree.folders = tree.folders.sortBy('name');
    }
    return tree;
  },
  getCalculatedFieldValues(datasource, field) {
    let data = {
      datasource,
      field,
      timezone: this.settingsService.timezone.trim(),
    };

    return new Promise((resolve, reject) => {
      this.executeAsyncQuery(
        '/describedatasource/calculatedfield/values/async',
        data,
        {
          onSuccess: (results) => {
            let values = [];

            results.results.forEach((row) => {
              values.pushObject(row[0]);
            });

            values = values.sort();
            resolve(values);
          },
          onFailure: reject,
        }
      );
    });
  },
  getFieldValues(database, table, field) {
    if (!database) {
      database = 'historical';
    }

    let cache = this.tablevalueCache;
    if (cache[table]) {
      if (cache[table][field]) {
        return Promise.resolve(cache[table][field]);
      }
    }
    return new Promise((resolve, reject) => {
      this.executeAsyncQuery(
        `/describedatasource/${database}/table/${table}/field/${field}/values/async`,
        null,
        {
          onSuccess: (results) => {
            let values = [];
            results.results.forEach((row) => {
              values.pushObject(row[0]);
            });
            values = values.sort();

            let cache = this.tablevalueCache;

            if (!cache[table]) {
              cache[table] = {};
            }

            cache[table][field] = values;
            this.set('tablevalueCache', cache);

            resolve(values);
          },
          onFailure: reject,
        }
      );
    });
  },
});
