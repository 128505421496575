import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style='width: 100%;height:{{this.height}}; '></div>\n{{/if}}", {"contents":"{{#if @isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style='width: 100%;height:{{this.height}}; '></div>\n{{/if}}","moduleName":"bi/components/report/chart-visual.hbs","parseOptions":{"srcName":"bi/components/report/chart-visual.hbs"}});
import Component from '@ember/component';
import ChartColors from '../../utils/chartcolors';
import textformatter from '../../utils/textformatter';
import VisualTypes from '../../utils/visualtypes';

import { inject as service } from '@ember/service';

const fontFamily = 'Arial'; // "sans-serif" // "Segoe UI";

import EchartMixin from '../../mixins/echart-mixin';

export default Component.extend(EchartMixin, {
  height: '100%',
  yAxisFormat: '',
  xAxisFormat: '',
  settingsService: service(),

  getDefaultOptions() {
    let yAxisFormat = this.yAxisFormat;
    let xAxisFormat = this.xAxisFormat;

    let legendType = 'plain';

    // specify chart configuration item and data
    return {
      grid: { containLabel: true },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          var tooltip = '';
          params.forEach((m) => {
            tooltip =
              textformatter.format(m.axisValue, xAxisFormat) +
              '<br/>' +
              m.marker +
              ' ' +
              m.seriesName +
              ' ' +
              textformatter.format(m.data, yAxisFormat) +
              '<br/>' +
              tooltip;
          });
          return tooltip;
        },
        textStyle: {
          fontFamily,
        },
      },
      legend: {
        type: legendType,
        data: [],
        textStyle: {
          fontFamily,
        },
      },

      toolbox: {
        orient: 'vertical',
        feature: {
          dataZoom: {
            show: true,

            title: {
              zoom: 'Zoom',
              back: 'Undo Zoom',
            },
          },
          restore: {
            title: 'Reset',
          },
        },
        tooltip: {
          confine: true,
        },
        right: '15px',
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 1,
        },
        axisLine: {
          show: true,
        },
      },
      yAxis: {
        axisLabel: {
          show: true,
        },
        type: 'value',
        axisLine: {
          show: true,
        },
      },
      series: [],
    };
  },
  processBarChart() {
    let visual = this.visual;
    let options = this.processLineChart();
    options.series.forEach((series) => {
      series.type = 'bar';
      series.barGap = 0;
      if (visual.displayProperties.showValues === 'true') {
        series.label.position = 'top';
      }

      series.barCategoryGap = '15%';
    });

    if (!this.isPrintView) {
      //If we have too much data, setup a scroller
      if (options.xAxis.data.length > 20) {
        options.dataZoom = [
          {
            type: 'slider',
          },
        ];
      }
    }

    return options;
  },
  processHorizontalBarChart() {
    if (!this.data) {
      return this.getDefaultOptions();
    }

    //reverse so that alphabetically A is at the top of the bar chart
    this.data.results.reverse();

    let options = this.processBarChart();
    options.grid = { containLabel: true };

    let yAxis = options.yAxis;

    options.yAxis = options.xAxis;
    options.xAxis = yAxis;

    options.yAxis.nameLocation = 'end';
    options.yAxis.nameGap = 5;

    if (!options.yAxis.nameTextStyle) {
      options.yAxis.nameTextStyle = {};
    }

    options.yAxis.nameTextStyle.align = 'right';

    let visual = this.visual;
    let xAxisFormat = this.xAxisFormat;

    options.series.forEach((series) => {
      if (visual.displayProperties.showValues === 'true') {
        series.label.position = 'insideRight';
        series.label.formatter = function (val) {
          return textformatter.format(val.data, xAxisFormat);
        };
      }
    });

    options.tooltip.formatter = function (params) {
      var tooltip = '';

      params.forEach((m) => {
        tooltip =
          m.marker +
          ' ' +
          m.seriesName +
          ' ' +
          textformatter.format(m.data, xAxisFormat) +
          '<br/>' +
          tooltip;
      });
      return tooltip;
    };

    if (options.dataZoom) {
      options.dataZoom[0].orient = 'vertical';
    }

    return options;
  },
  processHorizontalStackedBarChart() {
    let visual = this.visual;

    let options = this.processHorizontalBarChart();
    options.series.forEach((series) => {
      series.stack = `same`;

      if (visual.displayProperties.showValues === 'true') {
        series.label.position = 'insideRight';
      }
    });

    return options;
  },
  processStackedBarChart() {
    let options = this.processBarChart();
    options.series.forEach((series) => {
      series.stack = `same`;
    });

    return options;
  },
  processAreaChart() {
    let options = this.processLineChart();
    options.series.forEach((series) => {
      series.areaStyle = {};
    });

    return options;
  },
  processStackedAreaChart() {
    let options = this.processAreaChart();
    options.series.forEach((series) => {
      series.stack = `same`;
    });

    return options;
  },
  getOptions() {
    let chartType = this.visual.visualtype;

    let options = {};

    let visual = this.visual;

    if (!visual.displayProperties) {
      visual.displayProperties = {};
    }
    this.set('yAxisFormat', visual.displayProperties.yAxisFormat);
    this.set('xAxisFormat', visual.displayProperties.xAxisFormat);

    if (visual.displayProperties.normalizeAsPct === 'true') {
      if (chartType.indexOf('horizontal') > -1) {
        this.set('xAxisFormat', 'percent_0_decimals');
      } else {
        this.set('yAxisFormat', 'percent_0_decimals');
      }
    }

    let yAxisFormat = this.yAxisFormat;
    let xAxisFormat = this.xAxisFormat;

    let chartTypeConfig = {};

    VisualTypes.forEach((v) => {
      if (v.key === chartType) {
        chartTypeConfig = v;
      }
    });

    if (chartType === 'linegraph') {
      options = this.processLineChart();
    } else if (chartType === 'barchart') {
      options = this.processBarChart();
    } else if (chartType === 'stackedbarchart') {
      options = this.processStackedBarChart();
    } else if (chartType === 'areachart') {
      options = this.processAreaChart();
    } else if (chartType === 'stackedareachart') {
      options = this.processStackedAreaChart();
    } else if (chartType === 'horizontalbarchart') {
      options = this.processHorizontalBarChart();
    } else if (chartType === 'horizontalstackedbarchart') {
      options = this.processHorizontalStackedBarChart();
    }

    let axisInterval = 0;

    if (
      visual.groups &&
      visual.groups.length > 0 &&
      visual.groups[0].field &&
      visual.groups[0].field.type == 'timestamp'
    ) {
      axisInterval = null;
    }

    if (!options.xAxis) {
      options.xAxis = {};
    }

    options.xAxis.axisLabel = {
      formatter: function (val) {
        return textformatter.format(val, xAxisFormat);
      },
      rotate: 15,
      margin: [7], //The margin between the axis label and the axis line.
      // fontSize: 10,
      interval: axisInterval,
      fontFamily,
    };

    if (!options.yAxis) {
      options.yAxis = {};
    }
    options.yAxis.axisLabel = {
      formatter: function (val) {
        return textformatter.format(val, yAxisFormat);
      },
      interval: axisInterval,
      fontFamily,
    };

    if (!options.xAxis.nameTextStyle) {
      options.xAxis.nameTextStyle = {};
    }
    options.xAxis.nameTextStyle.fontFamily = fontFamily;

    if (!options.yAxis.nameTextStyle) {
      options.yAxis.nameTextStyle = {};
    }
    options.yAxis.nameTextStyle.fontFamily = fontFamily;

    options.series.forEach((series) => {
      series.markLine = {
        symbol: 'none',
        label: {
          formatter: '{b} {c}',
        },
        data: [],
      };

      if (this.visual.displayProperties.showMinLine === 'true') {
        series.markLine.data.pushObject({
          name: 'Min',
          type: 'min',
          label: {
            show: true,
            formatter: function (val) {
              return textformatter.format(val.data.value, yAxisFormat);
            },
          },
        });
      }

      if (this.visual.displayProperties.showMaxLine === 'true') {
        series.markLine.data.pushObject({
          name: 'Max',
          type: 'max',
          label: {
            show: true,
            formatter: function (val) {
              return textformatter.format(val.data.value, yAxisFormat);
            },
          },
        });
      }

      if (this.visual.displayProperties.showAvgLine === 'true') {
        series.markLine.data.pushObject({
          name: 'Avg',
          type: 'average',
          label: {
            show: true,
            formatter: function (val) {
              return textformatter.format(val.data.value, yAxisFormat);
            },
          },
        });
      }
    });

    if (
      chartTypeConfig.allowAlerts === true &&
      visual.alerts &&
      visual.alerts.length > 0
    ) {
      visual.alerts.forEach((a) => {
        if (a.min == '') {
          a.min = 0;
        }
        let alertSeries = {
          data: [],
          type: 'line',
          markArea: {
            itemStyle: {
              color: a.color,
              opacity: 0.9,
            },
            data: [
              [
                {
                  yAxis: a.min,
                },
                {
                  yAxis: a.max,
                },
              ],
            ],
          },
        };

        options.series.pushObject(alertSeries);
      });
    }

    if (this.data && this.data.columnInfo[0].type == 'timestamp') {
      options = this.reformatTimeSeriesDataOptions(options);
    }
    return options;
  },
  setDataAsPctOf100(seriesDataCount, series) {
    let totals = [];
    for (let x = 0; x < seriesDataCount; x++) {
      totals.pushObject(0);
    }

    //Build out new series Data
    for (let x = 0; x < series.length; x++) {
      for (let y = 0; y < seriesDataCount; y++) {
        if (series[x].data.length > y) {
          totals[y] = totals[y] + parseInt(series[x].data[y]);
        }
      }
    }

    //Reset Data as %
    for (let x = 0; x < series.length; x++) {
      for (let y = 0; y < seriesDataCount; y++) {
        if (series[x].data.length > y) {
          if (totals[y] == 0) {
            series[x].data[y] = 0;
          } else {
            let pct = parseInt(series[x].data[y]) / totals[y];
            if (isNaN(pct)) {
              series[x].data[y] = 0;
            } else {
              series[x].data[y] = pct;
            }
          }
        } else {
          series[x].data.pushObject(0);
        }
      }
    }

    return series;
  },
  //timeseries data requires a different data format so this function will remap to the new format
  reformatTimeSeriesDataOptions(options) {
    let timezone = this.settingsService.timezone.trim();

    let xAxisFormat = this.xAxisFormat;
    let yAxisFormat = this.yAxisFormat;

    options.xAxis.nameLocation = 'center';
    options.xAxis.nameTextStyle = {};
    options.xAxis.nameTextStyle.fontWeight = 'bold';
    options.xAxis.nameTextStyle.fontFamily = fontFamily;
    options.xAxis.nameGap = 35;
    options.xAxis.axisLabel = {
      showMinLabel: false,
      showMaxLabel: true,
      formatter: function (value) {
        return textformatter.format(
          moment.utc(value).tz(timezone).format(),
          xAxisFormat
        );
      },
    };

    options.tooltip.formatter = function (params) {
      var tooltip = '';
      params.forEach((m) => {
        var d = new Date(m.axisValue);
        tooltip =
          textformatter.format(
            moment.utc(m.axisValue).tz(timezone).format(),
            xAxisFormat
          ) +
          '<br/>' +
          m.marker +
          ' ' +
          m.seriesName +
          ' ' +
          textformatter.format(m.data[1], yAxisFormat) +
          '<br/>' +
          tooltip;
      });
      return tooltip;
    };

    options.xAxis.type = 'time';

    let xAxisData = options.xAxis.data;

    options.series.forEach((series) => {
      for (let x = 0; x < series.data.length; x++) {
        series.data[x] = [xAxisData[x], series.data[x]];
      }

      series.markLine.label.formatter = options.xAxis.axisLabel.formatter;
    });

    options.xAxis.data = null;

    return options;
  },
  processLineChart() {
    let options = this.getDefaultOptions();

    let data = this.data;
    let visual = this.visual;

    if (!data) {
      return options;
    }

    if (data.results.length === 0) {
      return options;
    }

    let properties = this.visual.displayProperties;
    let configuredColorMap = {};
    if (properties.colorMap) {
      configuredColorMap = JSON.parse(properties.colorMap);
    }

    let yAxisFormat = this.yAxisFormat;

    if (visual.groups.length > 1) {
      let seriesMap = {};

      data.results.forEach((row) => {
        let datamap = seriesMap;
        let series = [];
        for (let i = 0; i < row.length; i++) {
          let type = data.columnInfo[i].type;
          let nextType = '';

          if (data.columnInfo[i + 1]) {
            nextType = data.columnInfo[i + 1].type;
          }

          if (['varchar', 'string'].indexOf(type) > -1 || type == 'timestamp') {
            if (datamap[row[i]]) {
              datamap = datamap[row[i]];
            } else if (
              !datamap[row[i]] &&
              (['varchar', 'string'].indexOf(nextType) > -1 ||
                nextType == 'timestamp')
            ) {
              datamap[row[i]] = {};
              datamap = datamap[row[i]];
            } else if (
              !datamap[row[i]] &&
              ['varchar', 'string'].indexOf(nextType) == -1 &&
              nextType != 'timestamp'
            ) {
              datamap[row[i]] = [];
              series = datamap[row[i]];
            }
          } else {
            series.push(row[i]);
          }
        }
      });

      let subSeriesMap = {};

      let seriesIndex = [];

      Object.keys(seriesMap).forEach((key) => {
        options.xAxis.data.push(textformatter.format(key));
        if (seriesIndex.indexOf(key) === -1) {
          seriesIndex.push(key);
        }

        Object.keys(seriesMap[key]).forEach((sub) => {
          if (!subSeriesMap[sub]) {
            subSeriesMap[sub] = [];
          }

          while (subSeriesMap[sub].length <= seriesIndex.indexOf(key)) {
            subSeriesMap[sub].push(0);
          }

          subSeriesMap[sub][seriesIndex.indexOf(key)] = seriesMap[key][sub][0];
        });
      });

      let seriesKeys = Object.keys(subSeriesMap).sort((a, b) => {
        let sortOrder = 'asc';
        if (visual.orderby && visual.orderby.length >= 2) {
          sortOrder = visual.orderby[1].order.toLocaleLowerCase();
        }

        if (a && b) {
          if (sortOrder == 'asc') {
            return a.localeCompare(b);
          } else {
            return b.localeCompare(a);
          }
        }

        return 0;
      });

      for (let x = 0; x < seriesKeys.length; x++) {
        let seriesKey = seriesKeys[x];
        let series = {
          data: subSeriesMap[seriesKey],
          type: 'line',
          name: textformatter.format(seriesKey),
          stack: false,
          color: configuredColorMap[seriesKey] || ChartColors[x],
        };

        options.series.push(series);

        options.legend.data.pushObject(textformatter.format(seriesKey));
      }

      if (visual.displayProperties.normalizeAsPct === 'true') {
        options.series = this.setDataAsPctOf100(
          options.xAxis.data.length,
          options.series
        );

        //when zooming on 100%, the selecter removes some data so we will have to disable it
        options.toolbox.feature = {};
      }
    } else {
      for (let x = visual.groups.length; x < data.results[0].length; x++) {
        let valueIndex = x - visual.groups.length;

        let series = {
          data: [],
          type: 'line',
          name: textformatter.format(data.columnInfo[x].name),
          stack: false,
          color:
            configuredColorMap[visual.values[valueIndex].name] ||
            configuredColorMap[visual.values[valueIndex].field.column] ||
            ChartColors[valueIndex],
        };

        data.results.forEach((row) => {
          series.data.pushObject(row[x]);

          if (options.xAxis.data.indexOf(row[0]) == -1) {
            options.xAxis.data.pushObject(row[0]);
          }
        });

        options.series.pushObject(series);
      }

      for (let x = visual.groups.length; x < data.results[0].length; x++) {
        options.legend.data.pushObject(
          textformatter.format(data.columnInfo[x].name)
        );
      }
    }

    options.series.forEach((series) => {
      if (
        visual.displayProperties.showValues === 'true' ||
        visual.displayProperties.showValues === true
      ) {
        series.label = {
          show: true,
          position: 'top',
          formatter: function (val) {
            return textformatter.format(val.data, yAxisFormat);
          },
        };
      }
    });

    options.xAxis.name = data.columnInfo[0].name;
    options.xAxis.nameLocation = 'center';
    options.xAxis.nameTextStyle = {};
    options.xAxis.nameTextStyle.fontWeight = 'bold';
    options.xAxis.nameTextStyle.fontFamily = fontFamily;
    options.xAxis.nameGap = 35;

    return options;
  },
});
